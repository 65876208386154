import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

interface claimState {
  claim: any;
}

const initialState: claimState = {
  claim: {}
}

export const orderSlice = createSlice({
  name: 'claim',
  initialState,
  reducers: {
    clear: () => initialState,
    setClaim: (state, action) => {
      const claim = action.payload;
      if(claim.id == state.claim.id) return;

      state.claim = claim;
    },
    setClaimShop: (state, action) => {
      const { claimShop, index } = action.payload;
      const newClaim = _.cloneDeep(state.claim);
      const claim_shop = newClaim.claim_shops[index];

      newClaim.claim_shops[index] = {
        ...claim_shop,
        ...claimShop,
        claim_shop_products: claimShop.claim_shop_products ||
          claim_shop.claim_shop_products.map((item: any, i: number) => {
            return {
              ...item,
              ...(claimShop?.products?.[i] || {}),
            }
          })
        }

      state.claim = newClaim;
    },
  }
})

export const {
  setClaim,
  setClaimShop,
  clear,
} = orderSlice.actions

export default orderSlice.reducer
