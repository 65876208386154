import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

interface CategoryState {
  treeData: {[key: string]: any}[];
}

const initialState: CategoryState = {
  treeData: []
}

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    clear: () => initialState,
    setTreeData: (state, action) => {
      const categoriesData = action.payload?.data;
      const categoriesExtractData = categoriesExtract(categoriesData, "parent");

      state.treeData = categoriesExtractData;
    },
    onChangeTreeDataHandler: (state, action) => {
      state.treeData = action.payload;
    }
  }
})

const categoryRelations: {[key: string]: string} = {
  parent: "child",
  child: "nephew"
}

function categoriesExtract(categories: any, relation: string) {
  return categories?.map((item: { name: any; children: any; id: number | string;}) => {
    const childrens = item.children ? categoriesExtract(item.children, categoryRelations?.[relation]) : [];
    if(relation != "nephew") childrens.push({key: "adNew", relation: relation, parent: item.id});

    return {
      ...item,
      relation: relation,
      children: childrens,
    }
  })
}

export const {
  setTreeData,
  onChangeTreeDataHandler,
  clear
} = categorySlice.actions

export default categorySlice.reducer
