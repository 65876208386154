export const LIMIT = 10;
export const SUPER_ADMIN = "super_admin";
export const ADMIN = "admin";
export const MARKETER = "marketer";
export const SALER = "saler";
export const PARTNERSHIP_SUPPORTER = "partnership_supporter";
export const CUSTOMER = "customer";
export const SHIPMENT = "shipment";
export const STORE_OWNER = "store_owner";
export const STAFF = "staff";
export const TOKEN = "token";
export const PERMISSIONS = "permissions";
export const AUTH_CRED = "AUTH_CRED";
export const FILE_FORMATS = ["image/jpg", "image/png", "image/jpeg", "image/webp"];
