import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

interface ResultState {
  resultImportProductKeywords: any;
}

const initialState: ResultState = {
  resultImportProductKeywords: {
    "numOfImports": "0",
    "successImportProducts": "0",
    "successUpdateProducts": "0",
    "failedImportProducts": [],
    "duplicateImportProducts": [],
  }
}

export const resultImportProductKeywordsSlice = createSlice({
  name: 'resultImportProductKeywords',
  initialState,
  reducers: {
    clear: () => initialState,
    setResult: (state, action) => {
      if (action.payload != 1) {
        state.resultImportProductKeywords = action.payload;
      } else {
        clear();
      }
    },
  }
})

export const {
  setResult,
  clear
} = resultImportProductKeywordsSlice.actions

export default resultImportProductKeywordsSlice.reducer
