import Modal from "@components/ui/modal/modal";
import dynamic from "next/dynamic";
import { useModalAction, useModalState } from "./modal.context";
const TagDeleteView = dynamic(() => import("@components/tag/tag-delete-view"));
const TaxDeleteView = dynamic(() => import("@components/tax/tax-delete-view"));
const BanCustomerView = dynamic(() => import("@components/user/user-ban-view"));
const ShippingDeleteView = dynamic(
  () => import("@components/shipping/shipping-delete-view")
);
const CategoryDeleteView = dynamic(
  () => import("@components/category/category-delete-view")
);
const CouponDeleteView = dynamic(
  () => import("@components/coupon/coupon-delete-view")
);

const ProductDeleteView = dynamic(
  () => import("@components/product/product-delete-view")
);
const TypeDeleteView = dynamic(
  () => import("@components/group/group-delete-view")
);
const AttributeDeleteView = dynamic(
  () => import("@components/attribute/attribute-delete-view")
);

const ApproveShopView = dynamic(
  () => import("@components/shop/approve-shop-view")
);
const DisApproveShopView = dynamic(
  () => import("@components/shop/disapprove-shop-view")
);
const RemoveStaffView = dynamic(
  () => import("@components/shop/staff-delete-view")
);

const ExportImportView = dynamic(
  () => import("@components/product/import-export-modal")
);

const AttributeExportImport = dynamic(
  () => import("@components/attribute/attribute-import-export")
);

const UpdateMultiplePriceProduct = dynamic(
  () => import("@components/product/update-multiple-price-product")
);

const UpdateMultipleHightProduct = dynamic(
  () => import("@components/product/update-multiple-hight-product")
);

const UpdateMultipleHotProduct = dynamic(
  () => import("@components/product/update-multiple-hot-product")
);

const UpdateMultipleStatusProduct = dynamic(
  () => import("@components/product/update-multiple-status-product")
);

const UpdateMultipleStockProduct = dynamic(
  () => import("@components/product/update-multiple-stock-product")
);

const AttributeForm = dynamic(
  () => import("@components/attribute/attribute-form")
);
const AddCategory = dynamic(
  () => import("@components/category/category-form")
);
const ConfirmCalledUser = dynamic(
  () => import("@components/user/ultils/confirm-called-user")
);
const ConfirmResetPassword = dynamic(
  () => import("@components/user/ultils/confirm-reset-password")
);
const DirectionDeleteView = dynamic(
  () => import("@components/direction/direction-delete-view")
);

const OutsideOrderForm = dynamic(
  () => import("@components/order/outside-order-form")
);

const ExtendOrderPopup = dynamic(
  () => import("@components/order/ultil/popup")
);

const ConfirmRemovePromotion = dynamic(
  () => import("@components/promotion/ultils/confirm-remove-promotion")
)

const DeleteExtendOrder = dynamic(
  () => import("@components/order/edit/extend-order-delete-view")
);

const ImageZoomPopup = dynamic(
  () => import("@components/claims/image-zoom-popup")
);

const ImageProductZoomPopup = dynamic(
  () => import("@components/products/ultils/image-product-zoom-popup")
);

const CompanyIndustryForm = dynamic(
  () => import("@components/company-industries/industry-form")
);

const DeleteCompanyIndustry = dynamic(
  () => import("@components/company-industries/industry-delete-view")
);

const LinkableOrderPopup = dynamic(
  () => import("@components/order/linkable-order-popup")
);

const DeleteProductPopup = dynamic(
  () => import("@components/order/edit/delete-product-popup")
);

const PositionBannerPopup = dynamic(
  () => import("@components/banners/position-banner-popup")
);

const ImageBannerZoomPopup = dynamic(
  () => import("@components/banners/image-banner-zoom-popup")
);

const LanguageModal = dynamic(
  () => import("@components/management-ultils/ultils/language-modal")
);

const DeleteLanguageModal = dynamic(
  () => import("@components/management-ultils/ultils/language-delete-view")
);

const GoodOriginModal = dynamic(
  () => import("@components/management-ultils/ultils/good-origin-modal")
);

const DeleteGoodOrigin = dynamic(
  () => import("@components/management-ultils/ultils/good-origin-delete-view")
);

const DeleteShopPopup = dynamic(
  () => import("@components/order/ultil/delete-shop-popup")
);

const DeleteGiftPopup = dynamic(
  () => import("@components/gifts/confirm-remove-gift")
);

const SelectShopTypeGiftPopup = dynamic(
  () => import("@components/gifts/select-shop-type-gift")
);

const ProductKeywordPopup = dynamic(
  () => import("@components/keywords/product-popup")
);

const UnshowKeywordPopup = dynamic(
  () => import("@components/keywords/unshow-keyword-popup")
);

const DeleteKeywordPopup = dynamic(
  () => import("@components/keywords/delete-keyword-popup")
);

const InactiveShopPopup = dynamic(
  () => import("@components/shop/ultils/inactive-shop-popup")
);

const EditInactiveShopPopup = dynamic(
  () => import("@components/shop/ultils/edit-inactive-shop-popup")
);

const SuccessResizeImage = dynamic(
  () => import("@components/resize-images/success-modal")
);

const CloseShopPopup = dynamic(
  () => import("@components/shop/ultils/close-shop-popup")
);

const EditCloseShopPopup = dynamic(
  () => import("@components/shop/ultils/edit-close-shop-popup")
);


const ManagedModal = () => {
  const { isOpen, view, data } = useModalState();
  const { closeModal } = useModalAction();

  return (
    <Modal open={isOpen} onClose={closeModal}>
      {view === "DELETE_PRODUCT" && <ProductDeleteView />}
      {view === "DELETE_TYPE" && <TypeDeleteView />}
      {view === "DELETE_ATTRIBUTE" && <AttributeDeleteView />}
      {view === "DELETE_CATEGORY" && <CategoryDeleteView />}
      {view === "DELETE_COUPON" && <CouponDeleteView />}
      {view === "DELETE_TAX" && <TaxDeleteView />}
      {view === "DELETE_SHIPPING" && <ShippingDeleteView />}
      {view === "DELETE_TAG" && <TagDeleteView />}
      {view === "DELETE_COMPANY_INDUSTRY" && <DeleteCompanyIndustry />}
      {view === "BAN_CUSTOMER" && <BanCustomerView />}
      {view === "SHOP_APPROVE_VIEW" && <ApproveShopView />}
      {view === "SHOP_DISAPPROVE_VIEW" && <DisApproveShopView />}
      {view === "DELETE_STAFF" && <RemoveStaffView />}
      {view === "EXPORT_IMPORT_PRODUCT" && <ExportImportView />}
      {view === "EXPORT_IMPORT_ATTRIBUTE" && <AttributeExportImport />}
      {view === "UPDATE_MULTIPLE_PRICE_PRODUCT" && (
        <UpdateMultiplePriceProduct />
      )}
      {view === "UPDATE_MULTIPLE_HIGHLIGHT_PRODUCT" && (
        <UpdateMultipleHightProduct />
      )}
      {view === "UPDATE_MULTIPLE_HOT_PRODUCT" && <UpdateMultipleHotProduct />}
      {view === "UPDATE_MULTIPLE_STATUS_PRODUCT" && (
        <UpdateMultipleStatusProduct />
      )}
      {view === "UPDATE_MULTIPLE_STOCK_PRODUCT" && (
        <UpdateMultipleStockProduct />
      )}
      {view === "ATTRIBUTE_FORM" && <AttributeForm />}
      {view === "CATEGORY_FORM" && (
        <AddCategory />
      )}
      {view === "CONFIRM_CALLED_USER" && (
        <ConfirmCalledUser />
      )}
      {view === "CONFIRM_RESET_PASSWORD" && (
        <ConfirmResetPassword />
      )}
      {view === "CONFIRM_REMOVE_PROMOTION" && (
        <ConfirmRemovePromotion />
      )}
      {view === "DELETE_DIRECTION" && <DirectionDeleteView />}
      {view === "OUTSIDE_ORDER" && <OutsideOrderForm />}
      {view === "EXTEND_ORDER_POPUP" && <ExtendOrderPopup />}
      {view === "DELETE_EXTEND_ORDER" && <DeleteExtendOrder />}
      {view === "ZOOM_IMAGE_POPUP" && <ImageZoomPopup />}
      {view === "ZOOM_PRODUCT_IMAGE_POPUP" && <ImageProductZoomPopup />}
      {view === "COMPANY_INDUSTRY_POPUP" && <CompanyIndustryForm />}
      {view === "LINKABLE_ORDER_POPUP" && <LinkableOrderPopup order={data?.order} />}
      {view === "DELETE_PRODUCT_POPUP" && <DeleteProductPopup />}
      {view === "POSITION_BANNER_POPUP" && <PositionBannerPopup />}
      {view === "IMAGE_BANNER_ZOOM_POPUP" && <ImageBannerZoomPopup />}
      {view === "LANGUAGE_MODAL" && <LanguageModal />}
      {view === "DELETE_LANGUAGE" && <DeleteLanguageModal />}
      {view === "GOOD_ORIGIN_MODAL" && <GoodOriginModal />}
      {view === "DELETE_GOOD_ORIGIN" && <DeleteGoodOrigin />}
      {view === "DELETE_SHOP_ORDER_POPUP" && <DeleteShopPopup />}
      {view === "DELETE_GIFT_POPUP" && <DeleteGiftPopup />}
      {view === "SELECT_SHOP_TYPE_GIFT_POPUP" && <SelectShopTypeGiftPopup />}
      {view === "KEYWORD_PRODUCT_POPUP" && <ProductKeywordPopup />}
      {view === "UNSHOW_KEYWORD_CONFIRM_POPUP" && <UnshowKeywordPopup />}
      {view === "DELETE_KEYWORD_CONFIRM_POPUP" && <DeleteKeywordPopup />}
      {view === "INACTIVE_SHOP_POPUP" && <InactiveShopPopup />}
      {view === "EDIT_INACTIVE_SHOP_POPUP" && <EditInactiveShopPopup />}
      {view === "SUCCESS_RESIZE_IMAGES" && <SuccessResizeImage />}
      {view === "CLOSE_SHOP_POPUP" && <CloseShopPopup />}
      {view === "EDIT_CLOSE_SHOP_POPUP" && <EditCloseShopPopup />}
    </Modal>
  );
};

export default ManagedModal;
