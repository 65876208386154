export const API_ENDPOINTS = {
  ATTACHMENTS: "attachments",
  ANALYTICS: "dashboard-analytics",
  ATTRIBUTES: "admin/attributes",
  ATTRIBUTE_VALUES: "attribute-values",
  ORDER_STATUS: "order_status",
  ORDERS: "admin/orders",
  USERS: "admin/customers",
  REGISTER: "register",
  PRODUCTS: "products",
  ADMIN_PRODUCTS: "admin/products",
  ADMIN_PRODUCTS_QUICK_UPDATE: "admin/products/quick-update",
  POPULAR_PRODUCTS: "popular-products",
  COUPONS: "coupons",
  CUSTOMERS: "customers",
  TAXES: "admin/taxes",
  ADMIN_TAXES: "admin/taxes",
  SHIPPINGS: "shipping",
  SETTINGS: "settings",
  CATEGORIES: "fetch-categories",
  ADMIN_CATEGORIES: "admin/categories",
  TAGS: "tags",
  PARENT_CATEGORIES: "fetch-parent-category",
  TYPES: "types",
  PROFILE_UPDATE: "profile-update",
  LOGOUT: "logout",
  ME: "admin/info",
  TOKEN: "admin/token",
  BLOCK_USER: "users/ban-user",
  UNBLOCK_USER: "users/active-user",
  CHANGE_PASSWORD: "change-password",
  FORGET_PASSWORD: "forget-password",
  VERIFY_FORGET_PASSWORD_TOKEN: "verify-forget-password-token",
  RESET_PASSWORD: "reset-password",
  DOWNLOAD_INVOICE: "download/invoice",
  APPROVE_SHOP: "approve-shop",
  DISAPPROVE_SHOP: "disapprove-shop",
  SHOPS: "shops",
  MY_SHOPS: "my-shops",
  ADMIN_SHOPS: "admin/shops",
  WITHDRAWS: "withdraws",
  APPROVE_WITHDRAW: "approve-withdraw",
  STAFFS: "staffs",
  ADD_STAFF: "users/add-staff",
  REMOVE_STAFF: "users/remove-staff",
  IMPORT_PRODUCTS: "/admin/import-products",
  IMPORT_ATTRIBUTES: "import-attributes/",
  IMPORT_VARIATION_OPTIONS: "/admin/import-product-options",
  IMPORT_PRODUCT_RELATES: "/admin/import-product-relates",
  LATEST_PRODUCT_SHOP: "admin/get-lastest-product-shop",
  IMPORT_PRODUCT_CZ: "/admin/import-product-languages",
  EXPORT_PRODUCTS: "admin/export-products",
  EXPORT_PRODUCT_OPTIONS: "/admin/export-product-options",
  FETCH_CHILDREN_CATEGORY: "/admin/categories/fetch-by-parentIds",
  DELETE_ITEM_GALLERY: "admin/products/delete-item-gallery",
  ADMIN_CUSTOMER: "/admin/customers",
  ADMIN_CUSTOMER_ALL: "/admin/customers/all",
  INDUSTRIES: "/admin/industries",
  DIRECTION: "/admin/directions",
  REGION: "/admin/regions",
  FETCH_SHOP_REVIEWS: "get-rate-shops",
  EXTEND_ORDER_GENCODE: "admin/extendorders/gencode",
  EXTEND_ORDER_CREATE: "admin/extendorders/create",
  EXTEND_ORDER_SHOP: "/admin/extendordershops",
  PRODUCTS_SKU: "/products/sku",
  PROMOTIONS: "/admin/promotions",
  CLAIMS: "/admin/claims",
  CLAIM_SHOP: "/admin/claims/shop",
  ORDER_CLAIMS: "admin/orders/orderclaims",
  ORDER_IN_CLAIM: "orders/orders-in-claim",
  EMPLOYEES: '/admin/employees',
  INTERNAL_EXPORT_PRODUCTS: 'api/export',
  INTERNAL_EXPORT_PDF: 'api/pdf',
  SHOP_CATEGORIES: 'fetch-categories-by-shop',
  COMPANY: 'admin/company',
  COMPANY_INDUSTRIES: 'admin/category-services',
  SERVICES: 'admin/services',
  GET_SERVICE_DETAIL: 'admin/service',
  SERVICE_DELETE_ITEM_GALLERY: 'admin/services/delete-item-gallery',
  IMPORT_HOT_BEST_PRODUCT: 'admin/import-products-best-hot',
  IMPORT_PRODUCT_KEYWORDS: 'admin/import-product-keywords',
  BANNERS: '/admin/setting-banner-page',
  LANGUAGES: '/admin/languages',
  GOOD_ORIGINS: '/admin/source-goods',
  GIFTS: '/admin/giftprograms',
  ADMIN_KEYWORDS: "admin/productkeywords",
  ADMIN_PRODUCT_KEYWORDS: "admin/productkeywords/get-products-by-keywords",
  ADMIN_KEYWORD_HEADERS: "admin/productkeywordheaders",
  ADMIN_KEYWORD_HEADERS_SORT: "admin/productkeywordheaders/sort",
  ADMIN_EXPORT_PRODUCTS: 'admin/export-products',
  ADMIN_EXPORT_CATEGORIES: '/admin/export-cates',
  LIST_ORDERS: 'admin/listorders',
  ORDER_SHOPS: 'admin/order-shops',
  RESIZE_IMAGE_PRODUCTS: '/admin/products/resize-image-products'
};
