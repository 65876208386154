import React from "react";

type MODAL_VIEWS =
  | "DELETE_PRODUCT"
  | "DELETE_TYPE"
  | "DELETE_ATTRIBUTE"
  | "DELETE_CATEGORY"
  | "DELETE_ORDER"
  | "DELETE_COUPON"
  | "DELETE_TAX"
  | "DELETE_SHIPPING"
  | "DELETE_ORDER_STATUS"
  | "DELETE_TAG"
  | "DELETE_COMPANY_INDUSTRY"
  | "BAN_CUSTOMER"
  | "SHOP_APPROVE_VIEW"
  | "SHOP_DISAPPROVE_VIEW"
  | "DELETE_STAFF"
  | "EXPORT_IMPORT_PRODUCT"
  | "EXPORT_IMPORT_ATTRIBUTE"
  | "UPDATE_MULTIPLE_PRICE_PRODUCT"
  | "UPDATE_MULTIPLE_HIGHLIGHT_PRODUCT"
  | "UPDATE_MULTIPLE_HOT_PRODUCT"
  | "UPDATE_MULTIPLE_STATUS_PRODUCT"
  | "UPDATE_MULTIPLE_STOCK_PRODUCT"
  | "ATTRIBUTE_FORM"
  | "CATEGORY_FORM"
  | "CONFIRM_CALLED_USER"
  | "CONFIRM_RESET_PASSWORD"
  | "DELETE_DIRECTION"
  | "OUTSIDE_ORDER"
  | "EXTEND_ORDER_POPUP"
  | "CONFIRM_REMOVE_PROMOTION"
  | "DELETE_EXTEND_ORDER"
  | "ZOOM_IMAGE_POPUP"
  | "ZOOM_PRODUCT_IMAGE_POPUP"
  | "COMPANY_INDUSTRY_POPUP"
  | "LINKABLE_ORDER_POPUP"
  | "DELETE_PRODUCT_POPUP"
  | "POSITION_BANNER_POPUP"
  | "IMAGE_BANNER_ZOOM_POPUP"
  | "LANGUAGE_MODAL"
  | "DELETE_LANGUAGE"
  | "GOOD_ORIGIN_MODAL"
  | "DELETE_GOOD_ORIGIN"
  | "DELETE_SHOP_ORDER_POPUP"
  | "DELETE_GIFT_POPUP"
  | "SELECT_SHOP_TYPE_GIFT_POPUP"
  | "KEYWORD_PRODUCT_POPUP"
  | "UNSHOW_KEYWORD_CONFIRM_POPUP"
  | "DELETE_KEYWORD_CONFIRM_POPUP"
  | "INACTIVE_SHOP_POPUP"
  | "EDIT_INACTIVE_SHOP_POPUP"
  | "SUCCESS_RESIZE_IMAGES"
  | "CLOSE_SHOP_POPUP"
  | "EDIT_CLOSE_SHOP_POPUP";

interface State {
  view?: MODAL_VIEWS;
  data?: any;
  isOpen: boolean;
}
type Action =
  | { type: "open"; view?: MODAL_VIEWS; payload?: any }
  | { type: "close" };

const initialState: State = {
  view: undefined,
  isOpen: false,
  data: null,
};

function modalReducer(state: State, action: Action): State {
  switch (action.type) {
    case "open":
      return {
        ...state,
        view: action.view,
        data: action.payload,
        isOpen: true,
      };
    case "close":
      return {
        ...state,
        view: undefined,
        data: null,
        isOpen: false,
      };
    default:
      throw new Error("Unknown Modal Action!");
  }
}

const ModalStateContext = React.createContext<State>(initialState);
ModalStateContext.displayName = "ModalStateContext";
const ModalActionContext = React.createContext<
  React.Dispatch<Action> | undefined
>(undefined);
ModalActionContext.displayName = "ModalActionContext";

export const ModalProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(modalReducer, initialState);
  return (
    <ModalStateContext.Provider value={state}>
      <ModalActionContext.Provider value={dispatch}>
        {children}
      </ModalActionContext.Provider>
    </ModalStateContext.Provider>
  );
};

export function useModalState() {
  const context = React.useContext(ModalStateContext);
  if (context === undefined) {
    throw new Error(`useModalState must be used within a ModalProvider`);
  }
  return context;
}

export function useModalAction() {
  const dispatch = React.useContext(ModalActionContext);
  if (dispatch === undefined) {
    throw new Error(`useModalAction must be used within a ModalProvider`);
  }
  return {
    openModal(view?: MODAL_VIEWS, payload?: unknown) {
      dispatch({ type: "open", view, payload });
    },
    closeModal() {
      dispatch({ type: "close" });
    },
  };
}
