import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

interface ResultState {
  resultImportProductRelates: any;
}

const initialState: ResultState = {
  resultImportProductRelates: {
    "numOfImports": "0",
    "successImportProducts": "0",
    "successUpdateProducts": "0",
    "failedImportProducts": [],
    "duplicateImportProducts": [],
  }
}

export const resultImportProductRelatesSlice = createSlice({
  name: 'resultImportProductRelates',
  initialState,
  reducers: {
    clear: () => initialState,
    setResult: (state, action) => {
      if (action.payload != 1) {
        state.resultImportProductRelates = action.payload;
      } else {
        clear();
      }
    },
  }
})

export const {
  setResult,
  clear
} = resultImportProductRelatesSlice.actions

export default resultImportProductRelatesSlice.reducer
