import { combineReducers } from 'redux'
import categoryReducer from '@components/category/category-slice';
import resultImportProductSlice from '@components/import/result-import-product-slice';
import resultImportOptionSlice from '@components/import/result-import-option-slice';
import resultImportProductRelatesSlice from '@components/import/result-import-product-relates-slice';
import orderSlice from '@components/order/orderSlice';
import claimSlice from '@components/claims/claimSlice';
import resultImportHotProductSlice from '@components/import/result-import-hot-product-slice';
import resultImportBestProductSlice from '@components/import/result-import-best-product-slice';
import resultImportProductKeywordsSlice from '@components/import/result-import-product-keywords-slice';

export default combineReducers({
  category: categoryReducer,
  resultImportProduct: resultImportProductSlice,
  resultImportOption: resultImportOptionSlice,
  resultImportProductRelates: resultImportProductRelatesSlice,
  resultImportHotProduct: resultImportHotProductSlice,
  resultImportBestProduct: resultImportBestProductSlice,
  resultImportProductKeywords: resultImportProductKeywordsSlice,
  order: orderSlice,
  claim: claimSlice,
});
