export const ROUTES = {
  DASHBOARD: "/",
  SHOPS: "/shops",
  ADMIN_MY_SHOPS: "/my-shops",
  CREATE_SHOP: "/shops/create",
  LOGIN: "/login",
  ORDER_STATUS: "/order-status",
  ORDERS: "/orders",
  PRODUCTS: "/products",
  NEW_PRODUCT: "/products/new",
  COUPONS: "/coupons",
  USERS: "/users",
  TAXES: "/taxes",
  SHIPPINGS: "/shippings",
  SETTINGS: "/settings",
  STORE_SETTINGS: "/vendor/settings",
  STORE_KEEPER: "/vendor/store_keepers",
  CATEGORIES: "/categories",
  ATTRIBUTES: "/attributes",
  ATTRIBUTE_VALUES: "/attribute-values",
  GROUPS: "/groups",
  TAGS: "/tags",
  WITHDRAWS: "/withdraws",
  PROFILE_UPDATE: "/profile-update",
  LOGOUT: "/logout",
  STAFFS: "/staffs",
  IMPORT_PRODUCT: "/import-product",
  EXPORT_PRODUCT: "/export-product",
  MANAGEMENT_ACCOUNT: "/management-account",
  MANAGEMENT_DIRECTION: "/management-directions",
  PLAN_SHIPPINGS: "/plan-shippings",
  PROMOTION: "/promotion",
  CLAIMS: "/claims",
  EMPLOYEES: "/employees",
  COMPANIES: "/companies",
  SERVICES: "/services",
  COMPANY_INDUSTRIES: "/company-industries",
  HOME_BANNERS: '/banners',
  SEARCH_BANNERS: '/banners/search',
  CATEGORY_BANNERS: '/banners/category',
  SHOP_BANNERS: '/banners/shop',
  COMPANY_BANNERS: '/banners/company',
  NEW_PRODUCT_BANNERS: '/banners/new-product',
  HOT_PRODUCT_BANNERS: '/banners/hot-product',
  AKCE_PRODUCT_BANNERS: '/banners/akce-product',
  SHOP_DETAIL_BANNERS: '/banners/shop-detail',
  MANAGEMENT_ULTILS: "/management-ultils",
  GIFTS: '/gifts',
  NOT_FOUND: '/404',
  KEYWORDS: '/keywords',
  RESIZE_IMAGES: '/resize-images'
};
