import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

interface orderState {
  order: any;
  orderProducts: any;
  orderShops: any;
  extend_order_shops: any;
  order_calculator: {
    subtotal: string;
    tax_total: string;
    discount: number;
    total: number;
    original_subtotal: number;
    original_subtotal_cancelled: number | string;
  },
  cartShopIds: number[];
  currentProductPage: number;
  extend_orders: any;
}

const initialState: orderState = {
  order: {
    id: "",
    order_shops: "",
    quantity: "",
    subtotal: "",
    tax_total: "",
    status: "",
    discount: 0,
    total: 0,
  },
  orderProducts: {},
  orderShops: {},
  extend_order_shops: [],
  order_calculator: {
    subtotal: "0",
    tax_total: "0",
    discount: 0,
    total: 0,
    original_subtotal: 0,
    original_subtotal_cancelled: 0
  },
  cartShopIds: [],
  currentProductPage: 0,
  extend_orders: []
}

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    clear: () => initialState,
    setOrder: (state, action) => {
      const order = action.payload;
      if(order.code == state.order.code) return;

      state.order = order;
      const { subtotal, tax_total, discount, total, original_subtotal, original_subtotal_cancelled } = order;
      state.extend_order_shops = order.extend_order_shops;
      state.order_calculator = { subtotal, tax_total, discount, total, original_subtotal, original_subtotal_cancelled };

      if(order.code.includes("DNi33")) return;

      const { order_shops } = order;

      const cart_shops = order_shops?.reduce((obj: any, item: any) => {
        obj.orderShops[item.shop_id] = {...item, sortType: ''};
        item.order_products?.forEach((i: any, index: number) => {
          obj.orderProducts[item.shop_id] = obj.orderProducts[item.shop_id] || {};
          obj.orderProducts[item.shop_id][i.id] = {...i, index: index};
        })
        return obj;
      }, {orderProducts: {}, orderShops: {}});

      if(order.children && order.extend_orders) state.extend_orders = [...order.children, ...order.extend_orders];
      state.orderProducts = cart_shops.orderProducts;
      state.orderShops = cart_shops.orderShops;
      state.cartShopIds = [];
    },
    setOrderProducts: (state, action) => {
      const cartItems = action.payload || [{}];
      const orderProducts = {...state.orderProducts};
      const cartShopIds = [...state.cartShopIds];

      const { data, paginatorInfo } = cartItems.slice(-1).pop();

      if(state.currentProductPage == paginatorInfo.currentPage) return;

      data?.forEach((i: any, index: number) => {
        const cartShopId = i.shop_id;
        orderProducts[cartShopId] = orderProducts[cartShopId] || {};
        orderProducts[cartShopId][i.id] = {...i, index: ((paginatorInfo.currentPage - 1) * 15 + index)};

        if(!cartShopIds.includes(cartShopId)) cartShopIds.push(cartShopId);
      })

      state.orderProducts = orderProducts;
      state.cartShopIds = cartShopIds;
    },
    setExtendOrderShop: (state, action) => {
      const { extend_order_shop, index, setValue } = action.payload;

      if(setValue) {
        state.extend_order_shops[index] = extend_order_shop
      } else {
        state.extend_order_shops = [...state.extend_order_shops, {}];
      }
    },
    deleteExtendOrderShop: (state, action) => {
      const i = action.payload;
      const extendOrderShop = _.cloneDeep(state.extend_order_shops);
      state.extend_order_shops = extendOrderShop.filter((_item: any, index: any) => index !== i)
    },
    setNewOrder: (state, action) => {
      const { subtotal, tax_total, discount, total, original_subtotal, original_subtotal_cancelled } = action.payload;
      state.order_calculator = { subtotal, tax_total, discount, total, original_subtotal, original_subtotal_cancelled };
    },
    setExtendOrder: (state, action) => {
      state.extend_orders = [...state.extend_orders, action.payload];
    },
    setExtendOrders: (state, action) => {
      state.extend_orders = action.payload;
    },
    setOrderShop: (state, action) => {
      const orderShops = _.cloneDeep(state.orderShops);
      const orderShop = action.payload;

      if(orderShop.deleted) {
        delete orderShops[orderShop.shop_id]
      } else {
        orderShops[orderShop.shop_id] = {...orderShops[orderShop.shop_id], ...orderShop};
      }
      state.orderShops = orderShops;
    },
    deleteOrderProduct: (state, action) => {
      const { shopId, productId } = action.payload;
      const orderProducts =  _.cloneDeep(state.orderProducts);
      delete orderProducts[shopId][productId];
      state.orderProducts = orderProducts;
    },
    setOrderProduct: (state, action) => {
      const { shopId, orderProduct } = action.payload;
      const orderProducts =  _.cloneDeep(state.orderProducts);
      orderProducts[shopId] = orderProducts[shopId] || {};
      orderProducts[shopId][orderProduct?.id] = {...orderProducts[shopId][orderProduct?.id], ...orderProduct};
      state.orderProducts = orderProducts;
    },
    deleteExtend_orders: (state, action) => {
      const extend_order = action.payload;
      const extend_orders =  _.cloneDeep(state.extend_orders);
      state.extend_orders = extend_orders.filter((item: any) => item.id !== extend_order.id);
      ;
    },
    setOrderStatus: (state, action) => {
      const order =  _.cloneDeep(state.order);
      state.order = {...order, status: action.payload};
    },
    setDeliveryAt: (state, action) => {
      const order =  _.cloneDeep(state.order);
      state.order = {...order, delivery_at: action.payload};
    },
    updateInvoiceTypeAction: (state, action) => {
      const orderShops =  _.cloneDeep(state.orderShops);
      const {shopId, typeInvoice} = action.payload;

      orderShops[shopId].type_invoice = typeInvoice;
      state.orderShops = orderShops;
    }
  }
})

export const {
  setOrder,
  clear,
  setExtendOrderShop,
  deleteExtendOrderShop,
  setNewOrder,
  setDeliveryAt,
  setExtendOrder,
  setExtendOrders,
  setOrderShop,
  deleteOrderProduct,
  setOrderProduct,
  deleteExtend_orders,
  setOrderStatus,
  updateInvoiceTypeAction,
  setOrderProducts
} = orderSlice.actions

export default orderSlice.reducer
