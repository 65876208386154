import http from "@utils/api/http";

export default class Base<C, U> {
  http = async <T>(
    url: string,
    type: string,
    variables: T | null = null,
    options?: any
  ) => {
    return (http as any)[type](url, variables, options);
  };
  all = async (url: string, locale: string = "vi") => {
    return this.http(url, "get", {
      headers: { locale_code: locale }
    });
  };

  find = async (url: string, locale: string = "vi") => {
    return this.http(url, "get", {
      headers: { locale_code: locale }
    });
  };

  create = async (url: string, variables: C, locale: string = "vi") => {
    return this.http<C>(url, "post", variables, {
      headers: { 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8;application/json;', locale_code: locale }
    });
  };

  update = async (url: string, variables: U, locale: string = "vi") => {
    return this.http<U>(url, "post", variables, {
      headers: { 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8;application/json;', locale_code: locale }
    });
  };

  delete = async (url: string, locale: string  = "vi") => {
    return this.http(url, "delete", {
      headers: { locale_code: locale }
    });
  };

  updatePatch = async (url: string, variables: U, locale: string = "vi") => {
    return this.http<U>(url, "patch", variables, {
      headers: { locale_code: locale }
    });
  };

  updateShopPatch = async (url: string) => {
    return this.http<U>(url, "patch");
  };

  updatePut = async (url: string, variables: U, locale: string = "vi") => {
    return this.http<U>(url, "put", variables, {
      headers: { 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8;application/json;', locale_code: locale }
    });
  };
}
